body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content{
  padding-top: 15px;
  text-align: left;
  font-weight: lighter;
  font-size: xx-small;
  max-height: 0;
  overflow: hidden;
  transition: all 0.7s cubic-bezier(0,1,0,1);
  
}

.show{
  height: auto;
  max-height: 900px;
  transition: all 0.7s cubic-bezier(1,0,1,0);
  
}

@media screen and (min-width: 768px) {
  .content{
   
    font-size: medium;
   
  }
 
}


li {
  list-style: none
}


a{
  text-decoration: none;
  color: aliceblue;
  font-size: medium
}

header{
  position: relative;
  padding: 0 2rem;
}

.navbar {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  max-width: 1450px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 30;
}

.navbar .links{
  display: flex;
  gap: 2rem;
}

.toggle_btn{
  cursor: pointer;
  display: none;
  font-size: 1.5rem;
}

.dropdown_menu{
  display: none;
  position: absolute;
  left: 2rem;
  top: 62px;
  height: 0;
  width: 350px;
  background-color: #f6f8fa;
  z-index: 20;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px; 
  overflow: hidden;
  transition: height 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu.open{
  height: auto;
}

.input_div{
   position: relative;
   display: flex;
   align-items: center;
}

.dropdown_menu li{
  padding: 0.7rem;
}
@media screen and (max-width: 992px) {
 
.navbar .links{
  display: none;
}
.toggle_btn{
  display: block
}
.dropdown_menu{
  display: block;
}

.input_div{
  display: none;
}
}

@media screen and (max-width: 576px) {
 
  .dropdown_menu{
    left: 0;
    width: 100%;
    border-radius: 0;
  }
  }
  




@tailwind base;
@tailwind components;
@tailwind utilities;